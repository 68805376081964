
function Cus() {
    return (
        <div className="body">
            <div className="cuslf">

            </div>
            <div className="abs">
                
            
            <h1> Contact us : </h1>

                Welcome to our Contact Us page!
                <br></br> <br></br>
                We are always happy to hear from our customers and visitors. If you have any questions, comments, or concerns, please feel free to contact us using any of the methods below.
                <br></br> <br></br>
                <h3> Email: </h3>
                
                You can reach us by email at customercare@mis-tech.in. We typically respond to emails within 24 hours, although it may take longer during weekends or holidays.
                <br></br> <br></br>
                <h3>  Phone: </h3>
                You can also call us at +91-62074-83030. Our phone lines are open Monday to Friday, from 9am to 5pm IST.
                <br></br> <br></br>
                {/*<h3> Mailing Address: </h3>*/}
               
                {/*If you prefer to send us a letter or package, you can use the following address:*/}
                {/*<br></br> <br></br>*/}
                {/*1703, Primia F, Palava Lakeshore Greens, Dombivalli East, Thane 421204, Maharashtra, India*/}
                {/*<br></br> <br></br>*/}
                {/*<h3>Social Media: </h3>*/}
                {/*You can also connect with us on social media. We're active on Instagram, so feel free to send us a message or tag us in your posts.*/}

                

            </div>
            <div className="fpage" >
                <div className="flpage"></div>
            </div>

            <div className="epage">
                <h3>Feedback:  </h3>
                We welcome any feedback you may have about our website, products, or services. Please don't hesitate to share your thoughts with us, as we are always looking for ways to improve.
                <br></br> <br></br>
                Thank you for visiting our website, and we look forward to hearing from you soon!



            </div>



        </div>
    );
}

export default Cus;