
function Pa() {
    return (
        <div className="body">
            <div className="bgc">
            <div className="palf">

            </div>
            <div className="abs">
                
            
            <h1> Process Automation : </h1>

                Our Quick Automation team is your one-stop-shop for all your automation needs. We are a leading automation service provider offering a wide range of automation solutions to businesses of all sizes and industries.
                <br></br> <br></br>
                At MIS-Tech, we understand the importance of time and efficiency in today's fast-paced business environment. That's why we offer innovative automation solutions that streamline your business processes, increase productivity, and improve your bottom line.
                <br></br> <br></br>
                Our team of highly skilled automation experts works closely with you to understand your business needs and objectives. We design, develop, and implement automation solutions that are tailored to your unique requirements. Whether you need a simple automation solution or a complex one, we have the expertise to deliver it on time and within budget.
                <br></br> <br></br>
                We offer a wide range of automation solutions, including MIS Reporting Solutions, Website Building and Process Automation. Our solutions are designed to automate repetitive and time-consuming tasks, reduce errors, and free up your employees to focus on higher-value activities.
                <br></br> <br></br>
                At MIS-Tech, we are committed to delivering quality solutions and exceptional customer service. We work with you every step of the way, from initial consultation to implementation and ongoing support. We pride ourselves on delivering solutions that exceed your expectations and provide real business value.
                <br></br> <br></br>
                Contact us today to learn more about how  MIS-Tech can help automate your business processes and take your business to the next level.
            </div>

            </div>
        </div>
    );
}

export default Pa;