import React, { useRef } from 'react';

function ScrollToElement(sid) {
 
   /* alert(sid);*/

    const element = document.getElementById(sid);

    element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    });

  
}

export default ScrollToElement;
