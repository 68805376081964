import logo from './logo.svg';
import './App.css';
import Abs from './Abs';
import Mis from './Mis';
import Webd from './Webd'
import Cus from './Cus';
import ScrollToElement from './Scrol'
import Pa from './Pa'


function App() {
  return (
      <div className="App">
          <div className="body"><a href="javascript: void(0)" class="back-to-top"></a>
              <header className="App-header">
                  MIS-Tech -

              </header>
              
              <p className="sp" >Innovate and automate</p>

              <div className="vr">
                  <div className="fc" onClick={() => ScrollToElement('cu')}>
                      </div>
                  <div className="vrtext">
                      We provide solution for MIS reporting, website development and process automation.
                      
                  </div>
                  

              </div>

              <div className="rbox" >
                  <div className="r1" >

                      <div className="r11" id="rs1" onClick={()=>ScrollToElement('mr')} >
                          <div className="nameheader">
                          MIS Reporting
                          </div>
                          <br></br>
                          <br></br>
                          Welcome to our MIS (Management Information System) solution business!
                          <br></br>
                          <br></br>
                          ...Click to read more...
                      </div>
                  </div>

                  <div className="r2">
                      <div className="r11" id="rs2" onClick={() => ScrollToElement('pa')} >
                          <div className="nameheader">
                              Process Automation
                              </div>

                          <br></br>
                          <br></br>
                          Your one-stop-shop for all your automation needs.
                          <br></br>
                          <br></br>
                          ...Click to read more...
                      </div>
                  </div>

                  <div className="r3" >
                      <div className="r11" id="rs3" onClick={() => ScrollToElement('wb')}>
                          <div className="nameheader">
                          
                              Website Development

                          </div>
                          <br></br>
                          <br></br>
                          Get an attractive website created for your business.
                          <br></br>
                          <br></br>
                          ...Click to read more...
                      </div>
                  </div>

                  <div className="r4">
                      <div className="r11" id="rs4" onClick={() => ScrollToElement('cu')}>
                          <div className="nameheader">
                          Contact us
                          </div>
                          <br></br>
                          <br></br>
                          Email- customercare@mis-tech.in
                          <br></br>
                          Contact no- +91-6207483030
                          <br></br>
                          <br></br>
                          ...Click to read more...

                      </div>
                  </div>


              </div>

              <div className="bbox">

              </div>

              <div className="bbb"></div>
              
          </div>
          <div id="ab">
              <Abs></Abs>
          </div>
          <div id="mr">
              <Mis></Mis>
          </div>
          <div id="pa"></div>
          <Pa></Pa>
          <div id="wb"></div>
          <Webd></Webd>
          <div id="cu"></div>
          <Cus></Cus>

    </div>
  );
}

export default App;
